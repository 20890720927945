import React from 'react';

/*Main boards and role clasification*/
import ConstantsComponent from '../claims/Constants.jsx';
import Powerbi from '../components/PowerBi';


/*
4 - ingenieria
5 - comercial
1 - admin
*/
const Explorer = () => {
  /*Retrieve the role type from the ConstantsComponent */
  const { TYPE_ID} = ConstantsComponent();
  const mostrarBoarding = !isNaN(TYPE_ID) && parseInt(TYPE_ID, 10) === 4;
  const mostrarBoardcomer = !isNaN(TYPE_ID) && parseInt(TYPE_ID, 10) === 5;
  const mostrarBoardadmin = !isNaN(TYPE_ID) && parseInt(TYPE_ID, 10) === 1;
  /*Determine which board to display based on the role type ID 
  
  const mostrarBoardUser = !isNaN(TYPE_ID) && parseInt(TYPE_ID, 10) === 2;
  */
  
  /*Conditionally render the appropriate board based on the role type */
  return (
    <div className='container_graph'>
      {mostrarBoarding && <Powerbi id_u='e2632028-3520-41f4-bd22-1163be83829b' showUpdatePopup={false} showUpdateButton={false}/>}
      {mostrarBoardcomer && <Powerbi id_u='cc0dacd6-af1a-446f-a93b-585f6f8c1683' showUpdatePopup={false} showUpdateButton={false}/>}
      {mostrarBoardadmin && <Powerbi id_u='b1fbff5f-85b6-418a-9916-3058cc44ba69' showUpdatePopup={false} showUpdateButton={false}/>}
    </div>
  );
};
export default Explorer;
